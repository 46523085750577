import { useEffect, useRef, useState } from "react";
import AppInput from "../../shared/components/appInput/AppInput";
import { API_SERVICE, BASE_URL_IMAGE } from "../../shared/service";
import ReactPaginate from "react-paginate";
import checked from "../../assets/checked.png";
import unchecked from "../../assets/unchecked.png";
import SendEnquiry from "./SendEnquiry";
import { GridLoader } from "react-spinners";
import { loaderCSS } from "../../shared/components/Apploader/AppLoader";
import "./Products.scss";

interface ISearchProduct {
  categoryList: any;
  selectedCategory: any;
}

const SearchProduct: React.FunctionComponent<ISearchProduct> = (
  props: ISearchProduct
) => {
  const { categoryList, selectedCategory } = props;

  const [productList, setProductList] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [section, setSection] = useState("");
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [selectedProductIds, setSelectedProductIds] = useState<any>([]);
  const [imageForModel, setImageForModel] = useState("");

  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  const updateWidth = () => {
    const current = ref?.current as any;
    setWidth(current?.offsetWidth ?? 0);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  useEffect(() => {
    updateWidth();
  }, []);

  useEffect(() => {
    setCategory(selectedCategory);
    getProductList(1, selectedCategory);
  }, [selectedCategory]);

  const getProductList = (curentPage: number, categoryId?: string) => {
    const params = {
      productId: "",
      sectionNumber: section,
      categoryId: categoryId ?? category,
      productName: "",
      productStatus: "ACTIVE",
    };

    setLoading(true);
    API_SERVICE.getProductList(curentPage, params)
      .then(({ data }: any) => {
        setTotalPage(data?.payload?.totalPages ?? 0);
        setProductList(data?.payload?.content ?? []);
        setPage(curentPage);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageClick = (event: any) => {
    console.log("event", event);
    getProductList(event.selected + 1);
  };

  const renderPagination = () => {
    return (
      <div className="pagination-main">
        <ReactPaginate
          breakLabel="..."
          nextLabel="▶"
          forcePage={page - 1}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={totalPage}
          previousLabel="◀"
          renderOnZeroPageCount={null}
        />
      </div>
    );
  };

  const renderCheckbox = (productId: any) => {
    return (
      <button
        className="btn-check"
        onClick={() => {
          if (!selectedProductIds?.includes(productId)) {
            setSelectedProductIds([...selectedProductIds, productId]);
          } else {
            setSelectedProductIds(
              selectedProductIds?.filter((id: any) => id != productId)
            );
          }
        }}
      >
        <img
          src={selectedProductIds?.includes(productId) ? checked : unchecked}
          style={{
            width: 25,
            height: 25,
            objectFit: "contain",
          }}
          alt=""
        />
      </button>
    );
  };

  const renderText = (title: string, value: any) => {
    return (
      <span>
        {title}
        <span style={{ fontWeight: "bold" }}>{value}</span>
      </span>
    );
  };

  const renderImageModel = () => {
    return (
      <div id="myModal" className="modal">
        <div className="modal-content" style={{ width: "500px" }}>
          <span className="close" onClick={() => setImageForModel("")}>
            &times;
          </span>
          <p>Die drawing</p>
          <img
            // onClick={() => setImageForModel(imageForModel)}
            src={`${imageForModel}`}
            style={{
              height: 400,
              width: "100%",
              objectFit: "contain",
            }}
            alt=""
          />
        </div>
      </div>
    );
  };

  const renderGrid = () => {
    return (
      <div
        ref={ref}
        className="grid"
        style={{
          gridTemplateColumns: `repeat(auto-fit, minmax(${
            (width - 60) / (width <= 700 ? 3 : 4)
          }px, 1fr)`,
        }}
      >
        {loading ? (
          <GridLoader loading={loading} cssOverride={loaderCSS} size={15} />
        ) : (
          productList?.map((item: any) => {
            return (
              <>
                <div
                  key={item?.categoryId}
                  className="grid-item border-shadow "
                  style={{ position: "relative" }}
                >
                  {renderCheckbox(item?.productId)}
                  <img
                    onClick={() => setImageForModel(`${BASE_URL_IMAGE}${item.finalProductImagePath}`)}
                    src={`${BASE_URL_IMAGE}${item.finalProductImagePath}`}
                    style={{
                      height: (200 / 1190) * width,
                      width: "100%",
                      objectFit: "contain",
                    }}
                    alt=""
                  />
                  {renderText("Section No: ", item?.sectionNo)}
                  {/* {renderText("Product: ", item?.productName)} */}
                  {renderText("Category: ", item?.categoryName)}
                </div>
              </>
            );
          })
        )}

        {(productList?.length ?? 0) > 0 &&
          (productList?.length ?? 0) < 4 &&
          Array.from(Array(4 - (productList?.length ?? 0)).keys()).map(() => {
            return <div className="grid-item hide" />;
          })}
      </div>
    );
  };

  return (
    <div>
      {(imageForModel?.length ?? 0) > 0 && renderImageModel()}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          alignItems: "end",
        }}
      >
        <div
          style={{
            display: width <= 722 ? "grid" : "flex",
            gap: "10px",
            alignItems: "end",
          }}
        >
          <div style={{ display: "grid", gap: "4px" }}>
            <label>Select Category</label>
            <select
              name="category"
              className="app-select"
              value={category}
              onChange={(e) => {
                setSelectedProductIds([]);
                setCategory(e.target.value);
              }}
            >
              <option value={``}>{"All"}</option>
              {categoryList?.map((item: any) => {
                return (
                  <option key={item?.categoryId} value={`${item?.categoryId}`}>
                    {item?.categoryName}
                  </option>
                );
              })}
            </select>
          </div>
          <div style={{ display: "grid", gap: "4px" }}>
            <label>Section No</label>
            <AppInput
              placeholder="Search via Section No"
              onChange={(e) => setSection(e.target.value)}
            />
          </div>

          {/* <>Add category dropdown</> */}
          <button
            className="app-button"
            onClick={() => {
              getProductList(1);
            }}
          >
            Search
          </button>
        </div>
        <button
          className="app-button"
          disabled={(selectedProductIds?.length ?? 0) == 0}
          onClick={() => setShowSendEmail(true)}
        >
          Send Enquiry
        </button>
      </div>

      <div style={{ marginTop: "20px" }}>
        {loading ? (
          <GridLoader loading={loading} cssOverride={loaderCSS} size={15} />
        ) : (
          <>
            {/* {renderTable()} */}
            {renderGrid()}
            {renderPagination()}
          </>
        )}
      </div>

      {showSendEmail && (
        <SendEnquiry
          selectedProductIds={selectedProductIds}
          callback={(isSuccess: boolean) => {
            setShowSendEmail(false);
            if (isSuccess) {
              setSelectedProductIds([]);
            }
          }}
        />
      )}
    </div>
  );
};

export default SearchProduct;
