import { useState } from "react";
import AppInput from "../../shared/components/appInput/AppInput";
import AppTextarea from "../../shared/components/appInput/AppTextarea";
import { API_SERVICE } from "../../shared/service";
import AppAlert from "../../shared/components/alert/AppAlert";
import AppLoader from "../../shared/components/Apploader/AppLoader";
import "./Enquire.scss"

interface IEnquire {}

const Enquire: React.FunctionComponent<IEnquire> = (props: IEnquire) => {
  const [fName, setFName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const submitEnquire = (event: any) => {
    const params = {
      enquiryFormDetails: {
        firstName: fName,
        lastName: "",
        email: email,
        contactNumber: phone,
        message: message,
        address: "",
        companyName: company,
      },
    };

    setLoading(true);
    API_SERVICE.submitEnquire(params)
      .then(({ data }: any) => {
        setAlertTitle("");
        setAlertMessage(data?.message);
        event.target.reset();
        setTimeout(() => {
          setAlertMessage("");
        }, 3000);
      })
      .catch((e: any) => {
        setAlertTitle("Failed");
        setAlertMessage(API_SERVICE.handleErrors(e));
        setTimeout(() => {
          setAlertMessage("");
        }, 8000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    submitEnquire(event);
  };

  return (
    <>
      {loading && <AppLoader />}
      {(alertMessage?.length ?? 0) > 0 && (
        <AppAlert
          title={alertTitle}
          message={alertMessage}
          callback={() => setAlertMessage("")}
        />
      )}
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}
      >
        <form onSubmit={handleSubmit} style={{ display: "grid", gap: "10px" }}>
          <div className="form-imputs">
            <AppInput
              label="Name *"
              required
              placeholder="Enter name"
              onChange={(e) => setFName(e.target.value)}
            />
            <AppInput
              label="Email *"
              required
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-imputs">
            <AppInput
              label="Phone *"
              required
              type="number"
              placeholder="Enter phone"
              onChange={(e) => setPhone(e.target.value)}
            />
            <AppInput
              label="Company *"
              required
              placeholder="Enter company"
              onChange={(e) => setCompany(e.target.value)}
            />
          </div>
          {/* <AppTextarea
          placeholder="Address"
          onChange={(e) => setAddress(e.target.value)}
        /> */}
          <AppTextarea
            label="Message *"
            required
            placeholder="Enter message"
            onChange={(e) => setMessage(e.target.value)}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <button
              className="app-button border"
              type="submit"
              style={{ width: "150px" }}
            >
              Send Enquiry
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Enquire;
