import { CSSProperties } from "react";
import { FadeLoader } from "react-spinners";
import "./AppLoader.scss";

export const loaderCSS: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

interface IAppAlert {}

const AppLoader: React.FunctionComponent<IAppAlert> = (props: IAppAlert) => {
  return (
    <div className="loader-main">
      <div className="modal-content">
        <FadeLoader
          color="#ff656a"
          loading={true}
          cssOverride={loaderCSS}
          // size={15}
        />
      </div>
    </div>
  );
};

export default AppLoader;
