import { useCallback, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

interface IProductSlider {}

const ProductSlider: React.FunctionComponent<IProductSlider> = (
  props: IProductSlider
) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
      // partialVisibilityGutter: 20
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const [width, setWidth] = useState(0);
  const div = useCallback((node: any) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  return (
    <>
      <h2 ref={div} className="app-title" style={{ marginTop: "50px" }}>
        Industries we serve
      </h2>
      <div
        style={{
          width: width <= 722 ? "calc(100vw - 10px)" : `${width}px`,
          // width: `${width}px`,
          marginTop: "20px",
          backgroundColor: "rgb(238,238,238)",
          paddingBlock: "10px",
          paddingLeft: "10px",
        }}
      >
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          // showDots={true}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
        >
          {[
            {
              color: "#005588",
              title: "Construction and Architecture",
              image: "constructionAndArchitecture",
            },
            {
              color: "#559977",
              title: "Electricals and Electronics",
              image: "electricalsAndElectronics",
            },
            {
              color: "#575979",
              title: "Automation",
              image: "automation",
            },
            {
              color: "#987487",
              title: "Automotive",
              image: "automotive",
            },
            {
              color: "#123456",
              title: "Solar Panel",
              image: "solarPanel",
            },
            {
              color: "#005588",
              title: "Aerospace",
              image: "aerospace",
            },
            {
              color: "#559977",
              title: "Defense",
              image: "defence",
            },
            {
              color: "#575979",
              title: "Billboards",
              image: "billboards",
            },
            {
              color: "#987487",
              title: "Hardware",
              image: "hardware",
            },
            {
              color: "#123456",
              title: "Modular Furniture",
              image: "modularFurniture",
            },
            {
              color: "#005588",
              title: "Consumer Durables",
              image: "consumerDurables",
            },
            {
              color: "#559977",
              title: "Transmission and Distribution",
              image: "transmissionAndDistribution",
            },
            {
              color: "#575979",
              title: "Medical Equipment",
              image: "medicalEquipment",
            },
            {
              color: "#987487",
              title: "Marine and Ship Building",
              image: "marineAndShipBuilding",
            },
            {
              color: "#123456",
              title: "Engineering Applications",
              image: "engineeringApplications",
            },
          ].map((item: any, index) => {
            return (
              <div
                key={index}
                style={{
                  height: "190px",
                  // height: "100%",
                  // width: "210px",
                  // width: `${width / (width <= 700 ? 3 : 5) - 30}px`,
                  marginRight: "10px",
                  backgroundColor: item.color,
                  padding: "10px",
                }}
                className="border-shadow"
              >
                <img
                  src={require(`../../assets/Industries/${item?.image}.png`)}
                  style={{
                    height: 150,
                    width: "100%",
                    backgroundColor: item.color,
                    pointerEvents:'none'
                  }}
                  alt=""
                />
                <span style={{ fontSize: "14px" }}>{item.title}</span>
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

export default ProductSlider;
