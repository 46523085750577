import "./AppAlert.scss";

interface IAppAlert {
  title: string;
  message: any;
  callback: any;
}

const AppAlert: React.FunctionComponent<IAppAlert> = (props: IAppAlert) => {
  const { callback, message, title } = props;

  return (
    <div className="modal app-alert-main">
      <div className="modal-content pt-10 border-shadow">
        <span
          className="close"
          onClick={() => {
            callback(false);
          }}
        >
          &times;
        </span>
        <p className="mt-10" style={{ color: "red" }}>
          {title}
        </p>
        <div style={{ fontSize: "14px", color: title == "" ? "green" : "black" }}>
          {message}
        </div>
      </div>
    </div>
  );
};

export default AppAlert;
