import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./Banner.scss";

interface IBanner {}

const Banner: React.FunctionComponent<IBanner> = (props: IBanner) => {
  return (
    <>
      <div className="banner-view">
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          swipeable={true}
          showStatus={false}
        >
          {[
            { text: "From Classics to Specialties: We Cast Them All",image: "banner1"},
            { text: "Diverse Dies, Limitless Designs", image: "banner3" },
            { text: "Quick lead time, Boundless Opportunities", image: "banner2" },
            { text: "Our Markets: India and beyond", image: "banner4" },
            { text: "Acknowledged for LPG led Environmental Excellence", image: "banner5" },
          ].map((item, index) => {
            return (
              <div
                key={index}
                className="banner-item"
                style={{ position: "relative" }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    bottom: 0,
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <span className="text">{item.text}</span>
                  <div style={{ flex: 0.7 }} />
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ flex: 0.8 }} />
                  <img
                    src={require(`../../assets/Banner/${item.image}.png`)}
                    className="image"
                    alt=""
                  />
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

export default Banner;
