import { useEffect, useRef, useState } from "react";
import { API_SERVICE, BASE_URL_IMAGE } from "../../shared/service";
import { saveByteArray } from "../../shared/Utils";
import SendEnquiry from "./SendEnquiry";
import AppLoader from "../../shared/components/Apploader/AppLoader";
import "./Products.scss";
import AppAlert from "../../shared/components/alert/AppAlert";

interface IAllProduct {
  categoryList: any;
  setSelectedCategory: any;
  setOpenTab: any;
}

const AllProduct: React.FunctionComponent<IAllProduct> = (
  props: IAllProduct
) => {
  const { categoryList, setSelectedCategory, setOpenTab } = props;

  const [showSendEmail, setShowSendEmail] = useState(false);
  const [categoryId, setCategoryId] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("");

  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  const updateWidth = () => {
    const current = ref?.current as any;
    setWidth(current?.offsetWidth ?? 0);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  useEffect(() => {
    updateWidth();
  }, []);

  const downloadPdfFileForCategory = (categoryId: any) => {
    setLoading(true);
    API_SERVICE.downloadDocument(categoryId, (progress: any) => {
      if (progress >= 1) {
        setLoading(false);
      }
    })
      .then((data) => {
        console.log("data: ", data);
        const getFileName =
          data?.request
            ?.getResponseHeader("content-disposition")
            ?.split(";")[1]
            ?.split("=")[1]
            ?.trim() ?? `${categoryId}.pdf`;
        console.log("data?.request: ", data?.request);
        saveByteArray([data?.data], getFileName);
      })
      .catch((e: any) => {
        setAlertTitle("Failed");
        setAlertMessage(API_SERVICE.handleErrors(e));
        setTimeout(() => {
          setAlertMessage("");
        }, 5000);
        setLoading(false);
      });
  };

  const downloadCategory = (path: any, name: any) => {
    setLoading(true);

    API_SERVICE.downloadDocumentFromURL(
      `${BASE_URL_IMAGE}${path}${name}`,
      (progress: any) => {
        if (progress >= 1) {
          setLoading(false);
        }
      }
    )
      .then((data) => {
        saveByteArray([data?.data], name);
      })
      .catch((e: any) => {
        setAlertTitle("Failed");
        setAlertMessage(API_SERVICE.handleErrors(e));
        setTimeout(() => {
          setAlertMessage("");
        }, 5000);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <AppLoader />}
      {(alertMessage?.length ?? 0) > 0 && (
        <AppAlert
          title={alertTitle}
          message={alertMessage}
          callback={() => {
            setAlertMessage("");
          }}
        />
      )}
      <div
        ref={ref}
        className="grid"
        style={{
          gridTemplateColumns: `repeat(auto-fit, minmax(${
            (width - 60) / (width <= 722 ? 3 : 5)
          }px, 1fr)`,
        }}
      >
        {categoryList?.map((item: any) => {
          return (
            <div key={item?.categoryId} className="grid-item border-shadow ">
              <img
                /* src={`data:image/png;base64,${item.categoryImageByteArray}`} */
                src={`${BASE_URL_IMAGE}${item.finalImagePath}`}
                style={{
                  // height: 200,
                  height: (200 / 1190) * width,
                  width: "100%",
                  backgroundColor: "aliceblue",
                }}
                alt=""
              />
              <span>{item?.categoryName}</span>
              <button
                className="btn-link"
                onClick={() => {
                  setSelectedCategory(item?.categoryId);
                  setOpenTab(1);
                }}
              >
                View Die Drawing
              </button>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: "5px",
                  marginTop: "6px",
                }}
              >
                {/* <button
                  className="app-button border"
                  style={{ paddingInline: "8px", fontSize: "12px" }}
                  onClick={() => {
                    //downloadCategory(item?.categoryPDFNginxPath, item?.categoryPDFNamesFiles?.at(0));
                    downloadPdfFileForCategory(item?.categoryId);
                  }}
                >
                  Download PDF
                </button> */}
                <a
                  href={`${BASE_URL_IMAGE}${
                    item?.categoryPDFNginxPath
                  }${item?.categoryPDFNamesFiles?.at(0)}`}
                  target="_blank"
                  className="app-button border"
                  style={{
                    textDecoration: "none",
                    textAlign:'center',
                    paddingInline: "8px",
                    fontSize: "12px",
                  }}
                >
                  Download PDF
                </a>
                <button
                  className="app-button border"
                  style={{ paddingInline: "8px", fontSize: "12px" }}
                  onClick={() => {
                    setCategoryId(item?.categoryId);
                    setShowSendEmail(true);
                  }}
                >
                  Send PDF
                </button>
              </div>
            </div>
          );
        })}
      </div>
      {showSendEmail && (
        <SendEnquiry
          categoryId={categoryId}
          callback={(isSuccess: boolean) => {
            setShowSendEmail(false);
            setCategoryId("");
          }}
        />
      )}
    </>
  );
};

export default AllProduct;
