import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  PATH_ABOUT,
  PATH_CONTECT_US,
  // PATH_ENQUIRE,
  PATH_MANU_CAP,
  PATH_PRIVACY_POLICE,
  PATH_CATALOGUE,
  // PATH_QUALITY,
  PATH_TERMS_CONDI,
} from "./RouteConstants";
import PrivacyPolicy from "../other/PrivacyPolicy";
import BusinessCommunication from "../businessCommunication/BusinessCommunication";
import Products from "../products/Products";
import Enquire from "../enquire/Enquire";
import ContactUs from "../contactUs/ContactUs";
import AboutUs from "../aboutUs/AboutUs";
import TermsCondition from "../other/TermsCondition";
import ManufacturingCapablity from "../manufacturingCapablity/ManufacturingCapablity";

type Props = {};

const AppRoutes: React.FunctionComponent<Props> = () => {
  return (
    <Routes>
      <Route path={PATH_ABOUT} element={<AboutUs />} />
      <Route
        path={PATH_CONTECT_US}
        element={<ContactUs />}
      />
      <Route path={PATH_MANU_CAP} element={<ManufacturingCapablity />} />
      <Route path={PATH_CATALOGUE} element={<Products />} />
      {/* <Route path={PATH_QUALITY} element={<BusinessCommunication />} /> */}
      {/* <Route path={PATH_ENQUIRE} element={<Enquire />} /> */}
      <Route path={PATH_PRIVACY_POLICE} element={<PrivacyPolicy />} />
      <Route path={PATH_TERMS_CONDI} element={<TermsCondition />} />

      <Route path="/*" element={<Navigate to={PATH_ABOUT} />} />
    </Routes>
  );
};

export default AppRoutes;
