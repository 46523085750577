import { CSSProperties, useEffect, useState } from "react";
import { API_SERVICE } from "../../shared/service";
import "./Products.scss";
import AllProduct from "./AllProduct";
import SearchProduct from "./SearchProduct";
import { GridLoader } from "react-spinners";
import { loaderCSS } from "../../shared/components/Apploader/AppLoader";

interface IProducts {}

const Products: React.FunctionComponent<IProducts> = () => {
  const [openTab, setOpenTab] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = () => {
    setLoading(true);
    API_SERVICE.getCategory()
      .then(({ data }: any) => {
        setCategoryList(data?.payload);
      })
      .catch((e: any) => {
        setLoading(false);
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="custome-tab">
        <div className="tab">
          <button
            className={`tablinks ${openTab == 0 ? "active" : ""}`}
            onClick={() => {
              setSelectedCategory("");
              setOpenTab(0);
            }}
          >
            Categories
          </button>
          <button
            className={`tablinks ${openTab == 1 ? "active" : ""}`}
            onClick={() => setOpenTab(1)}
          >
            Section
          </button>
        </div>

        <div className="tabcontent">
          {loading ? (
            <GridLoader loading={loading} cssOverride={loaderCSS} size={15} />
          ) : openTab == 0 ? (
            <AllProduct
              categoryList={categoryList}
              setSelectedCategory={setSelectedCategory}
              setOpenTab={setOpenTab}
            />
          ) : (
            <SearchProduct
              categoryList={categoryList}
              selectedCategory={selectedCategory}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Products;
