import { useNavigate } from "react-router-dom";
import {
  PATH_ABOUT,
  PATH_CONTECT_US,
  PATH_MANU_CAP,
  PATH_CATALOGUE,
} from "./RouteConstants";
// import logo from "../../assets/logo.png";
import logo1 from "../../assets/logo.jpg";
import "./Layout.scss";

interface IAppHeader {}

const AppHeader: React.FunctionComponent<IAppHeader> = () => {
  const Navigate = useNavigate();

  const path = new URL(window.location.href).pathname;

  return (
    <div className="header-main">
      <div className="sub">
        <img
          src={logo1}
          // src={logo}
          alt=""
          className="logo" // Change in class also for logo
        />
        <div className="view-btn">
          <button
            className={`btn-header ${path === PATH_ABOUT ? "selected" : ""}`}
            onClick={() => Navigate(PATH_ABOUT)}
          >
            About
          </button>
          <button
            className={`btn-header ${
              path === PATH_CATALOGUE ? "selected" : ""
            }`}
            onClick={() => Navigate(PATH_CATALOGUE)}
          >
            Catalogue
          </button>
          <button
            className={`btn-header ${path === PATH_MANU_CAP ? "selected" : ""}`}
            onClick={() => Navigate(PATH_MANU_CAP)}
          >
            Manufacturing & Quality Control
          </button>
          {/* <button
            className={`btn-header ${path == PATH_QUALITY ? "selected" : ""}`}
            onClick={() => Navigate(PATH_QUALITY)}
          >
            Quality Control
          </button> */}
          <button
            className={`btn-header ${
              path === PATH_CONTECT_US ? "selected" : ""
            }`}
            onClick={() => Navigate(PATH_CONTECT_US)}
          >
            Contact
          </button>
          {/* <button
            className={`btn-header ${path == PATH_ENQUIRE ? "selected" : ""}`}
            onClick={() => Navigate(PATH_ENQUIRE)}
          >
            Enquire
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
