interface IAppTextarea {
  label?: string;
  placeholder?: string;
  required?: boolean;
  dValue?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
}

const AppTextarea: React.FunctionComponent<IAppTextarea> = (
  props: IAppTextarea
) => {
  const { label, placeholder, required, dValue, onChange } = props;

  return (
    <div className="app-input-main">
      <label>{label}</label>
      <textarea
        defaultValue={dValue}
        className="app-input"
        required={required}
        placeholder={placeholder}
        onChange={onChange}
        rows={4}
      />
    </div>
  );
};

export default AppTextarea;
