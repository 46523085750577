// import { GoogleApiWrapper } from "google-maps-react";
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import { useMemo, useState } from "react";
import "./MapView.scss";

const MapView: React.FunctionComponent<any> = (props: any) => {
  //   <script src="https://maps.googleapis.com/maps/api/geocode/json?latlng"></script>;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCvken4fRHbFwAKdNlJmwEsbwImqozPE60",
  });
  const lat = 19.26043;
  const lng = 73.389999;
  const center = useMemo(() => ({ lat, lng }), []);

  const [isOpen, setIsOpen] = useState(false);

  const markers = [{ lat, lng }];

  const onMapLoad = (map: any) => {
    const bounds = new google.maps.LatLngBounds();
    markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
    setTimeout(() => {
      setIsOpen(true);
    }, 1000);
  };

  const handleMarkerClick = (lat: any, lng: any) => {
    setIsOpen(true);
  };

  return (
    <>
      {isLoaded && (
        <GoogleMap
          onLoad={onMapLoad}
          mapContainerClassName="map-container"
          center={center}
          zoom={17}
        >
          <MarkerF
            position={{ lat, lng }}
            onClick={() => {
              handleMarkerClick(lat, lng);
            }}
          >
            {isOpen && (
              <InfoWindowF
                onCloseClick={() => {
                  setIsOpen(false);
                }}
              >
                <>
                  <span style={{ fontWeight: "bold" }}>
                    {"RAMCO EXTRUSION PVT. LTD."}
                  </span>
                  <br />
                  <span>Manufacturing Plant</span>
                  <br />
                  <a
                    href="https://www.google.com/maps/dir//Ramco+Extrusion+Pvt+Ltd+Murbad,+BSNL+Telephone+Exchange,+Additional+MIDC,+Murbad,+Maharashtra/@22.2182574,70.8428018,8z/data=!4m9!4m8!1m0!1m5!1m1!1s0x3be78b24c2a10a57:0xd6ae4c981acbe78c!2m2!1d73.3901946!2d19.2604313!3e0?entry=ttu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Directions
                  </a>
                </>
              </InfoWindowF>
            )}
          </MarkerF>
        </GoogleMap>
      )}
      {/* <iframe
        aria-hidden="false"
        frameBorder="0"
        style={{ height: "250px", width: "100%" }}
        src={
          "https://maps.google.com/maps?q=" +
          "19.26043" +
          "," +
          "73.389999" +
          "&width=100&height=600&hl=en;z=10&ie=UTF8&iwloc=B&output=embed"
        }
      /> */}
    </>
  );
};

export default MapView;
// export default GoogleApiWrapper({
//   apiKey: "AIzaSyCvken4fRHbFwAKdNlJmwEsbwImqozPE60",
// })(MapView);
