import AXIOS from "axios";

// const BASE_URL = "http://localhost:9091/"; // Local
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const BASE_URL_IMAGE = process.env.REACT_APP_BASE_URL_IMAGE;

//const BASE_URL = "http://62.72.58.52:9091/"; // uat
//export const BASE_URL_IMAGE = "http://62.72.58.52:3001/"; // uat

//const BASE_URL = "http://62.72.58.52:9092/"; // prod
//export const BASE_URL_IMAGE = "http://62.72.58.52:3002/"; // prod

//const BASE_URL = "https://62.72.58.52:9091/"; // UAT SSL
//export const BASE_URL_IMAGE = "https://62.72.58.52:3001/"; // UAT Image  SSL

//const BASE_URL = "https://62.72.58.52:9092/"; // PROD SSL
//export const BASE_URL_IMAGE = "https://62.72.58.52:3002/"; // PROD Image SSL


function getPublicInstance() {
console.log("BASE_URL:: "+BASE_URL)
  const headers: any = {};
  return AXIOS.create({
    //@ts-ignore
    accept: "application/json",
    baseURL: BASE_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
  });
}

function getPublicInstanceForBlob(progress: any) {
  const headers: any = {};
  return AXIOS.create({
    //@ts-ignore
    accept: "application/json",
    baseURL: BASE_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
    responseType: "blob",
    onDownloadProgress: (progressEvent) => {
      progress(progressEvent?.progress);
    },
  });
}

function handleErrors(error: any) {
  let message = "Something went wrong!!";
  if (error && error.response && error?.response?.error) {
    let parsedError: any = "";
    message = error.response.data.error;
    if (typeof error.response.data.error === "string") {
      try {
        parsedError = JSON.parse(error.response.data.error);
        if (parsedError["errorMessage"]) {
          message = parsedError["errorMessage"];
        } else {
          const allErrors = Array();
          for (let i in parsedError) {
            allErrors.push(parsedError[i]);
          }
          message = allErrors.join(", ");
        }
      } catch (e) {}
    }
  } else if (error?.response?.data?.payload?.errorMessage) {
    message = error.response.data.payload.errorMessage;
  } else if (error.response?.data?.message) {
    return (message = error.response.data.message);
  } else if (error?.response?.data?.errors) {
    let e = Array();
    for (let i in error.response?.data?.errors) {
      e.push(`${i}: ${error.response.data.errors[i]}`);
    }
    message = e.join(",");
  } else if (typeof error === "string") {
    message = error;
  }
  // notification.error({ message: message });
  return message;
}

async function getEnumValues() {
  return await getPublicInstance().get("api/v1/free/enum-values");
}

async function getBusinessCommunication(type: string) {
  return await getPublicInstance().get(
    `api/v1/business-communication/free/search-by-type/${type}`
  );
}

async function getCategory() {
  return await getPublicInstance().get(
    `api/v1/category/free/list?categoryStatus=ACTIVE`
  );
}

async function getProductList(page: number, params: any) {
  return await getPublicInstance().post(
    `api/v1/product/free/search?pageNo=${page}&pageSize=12`,
    params
  );
}

async function submitEnquire(params: any) {
  return await getPublicInstance().post(`api/v1/enquiry/free/create`, params);
}

async function shareProductViaEmail(params: any) {
  return await getPublicInstance().post(
    `api/v1/product/free/share-product-via-email`,
    params
  );
}

async function sendPDFViaEmail(params: any) {
  return await getPublicInstance().post(
    `api/v1/category/free/send-pdf-via-email`,
    params
  );
}

async function downloadDocument(categoryId: any, progress: any) {
  const url = `api/v1/category/free/download-pdf/${categoryId}`;
  return await getPublicInstanceForBlob(progress).get(url);
}

async function downloadDocumentFromURL(url: any, progress: any) {
  return await getPublicInstanceForBlob(progress).get(url);
}

export const API_SERVICE = {
  handleErrors,
  getEnumValues,
  getBusinessCommunication,
  getCategory,
  getProductList,
  submitEnquire,
  shareProductViaEmail,
  downloadDocument,
  sendPDFViaEmail,
  downloadDocumentFromURL,
};
