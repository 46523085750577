interface IManufacturingCapablity {}

const ManufacturingCapablity: React.FunctionComponent<
  IManufacturingCapablity
> = (props: IManufacturingCapablity) => {
  const content =
    '<html><head><meta name=\"viewport\" content=\"width=device-width, initial-scale=0.8\"></head><body style=\"padding: 10\"><div class=\"row content\"><div class=\"col-lg-6\"><p><b>Ramco\'s </b> manufacturing facility is equipped to produce even the most intricate extrusion profiles with precision and efficiency. Whether you require customized extrusions or standard profiles, our skilled team has the capability and flexibility to meet your unique requirements.</p><p>Our state-of-the-art manufacturing facility includes:<ul><li><i class=\"ri-check-double-line\"></i> Melting furnace </li><li><i class=\"ri-check-double-line\"></i> Hot log furnace</li><li><i class=\"ri-check-double-line\"></i> 1400-ton extrusion press</li><li><i class=\"ri-check-double-line\"></i> 1200-ton extrusion press</li><li><i class=\"ri-check-double-line\"></i> Die Tool room</li><li><i class=\"ri-check-double-line\"></i> EDM Machine</li><li><i class=\"ri-check-double-line\"></i> VMC Machine</li></ul></p><p>We are constantly investing in new technologies to ensure that we remain at the forefront of the extrusion industry.</p></div></div></body></html>';
    //<li><i class=\"ri-check-double-line\"></i> Ageing oven</li>

  const contentQuality =
    '<html><head><meta name="viewport" content="width=device-width, initial-scale=0.8"></head><body style="padding: 10"><div class="row content"><div class="col-lg-6"><p>At <b>Ramco</b>, we are committed to providing our customers with the highest quality profiles that meet or exceed national and international standards. To ensure the quality and consistency of our products, we employ rigorous quality control measures in our manufacturing process. Our state-of-the-art quality control systems includes:<ul><li style="margin-bottom: 15px"><i class="ri-check-double-line"></i> <b>Spectrometer</b> - To analyze the chemical composition of the aluminium alloy to ensure consistent product quality.</li><li style="margin-bottom: 15px"><i class="ri-check-double-line"></i> <b>Universal tensile machine </b>- to assess the strength of the extruded profiles so as to ensure that it meets the required specifications and can withstand the intended loads and stresses.</li><li style="margin-bottom: 15px"><i class="ri-check-double-line"></i> <b>Webster </b>- Hardness testing is performed to assure that the extruded profiles possess the requisite level of hardness suitable for their intended use.</li></ul></p><p>We stand behind the quality of our products and are dedicated to continuous improvement to meet the evolving needs of our valued customers.</p></div></div></body></html>';

  return (
    <>
      <div>
        <h2 className="app-title">Manufacturing Capability</h2>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      <div>
        <h2 className="app-title">Quality Control</h2>
        <div dangerouslySetInnerHTML={{ __html: contentQuality }} />
      </div>
    </>
  );
};

export default ManufacturingCapablity;
