
export const base64ToArrayBuffer = (base64: string) => {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export const saveByteArray = (function () {
  var a = document.createElement("a") as any;
  document.body.appendChild(a);
  a.style = "display: none";
  return function (data: BlobPart[] | undefined, name: string) {
    var blob = new Blob(data, { type: "octet/stream" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();
