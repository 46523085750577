import { useEffect, useState } from "react";
import "./Products.scss";
import AppInput from "../../shared/components/appInput/AppInput";
import AppTextarea from "../../shared/components/appInput/AppTextarea";
import { API_SERVICE } from "../../shared/service";
import AppAlert from "../../shared/components/alert/AppAlert";
import AppLoader from "../../shared/components/Apploader/AppLoader";

interface ISendMail {
  categoryId?: any;
  selectedProductIds?: any;
  callback: any;
}

const SendEnquiry: React.FunctionComponent<ISendMail> = (props: ISendMail) => {
  const { callback, selectedProductIds, categoryId } = props;

  const [fName, setFName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoandig] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [showMainView, setShowMainView] = useState(true);

  useEffect(() => {
    if ((categoryId?.length ?? 0) > 0) {
      const userDetails = localStorage.getItem("UserDetails") as any;
      if (userDetails) {
        const user = JSON.parse(userDetails);
        setFName(user?.fullName ?? "");
        setPhone(user?.contactNumber ?? "");
        setEmail(user?.customerEmailId ?? "");
        setMessage(user?.message ?? "");
      }
    } else {
      const userDetails = localStorage.getItem("UserDetailsEmail") as any;
      if (userDetails) {
        const user = JSON.parse(userDetails);
        setFName(user?.fullName ?? "");
        setPhone(user?.contactNumber ?? "");
        setMessage(user?.message ?? "");
      }
    }
  }, []);

  const shareProductViaEmail = () => {
    if (loading) {
      return;
    }

    const params = {
      productIds: selectedProductIds,
      fullName: fName,
      contactNumber: Number(phone),
      message: message,
      source: "web",
    };

    localStorage.setItem("UserDetailsEmail", JSON.stringify(params));

    setLoandig(true);
    API_SERVICE.shareProductViaEmail(params)
      .then(({ data }: any) => {
        setAlertTitle("");
        setAlertMessage(data?.payload ?? "");
        setShowMainView(false);
        setTimeout(() => {
          setAlertMessage("");
          callback(true);
        }, 3000);
      })
      .catch((e: any) => {
        setAlertTitle("Failed");
        setAlertMessage(API_SERVICE.handleErrors(e));
        setTimeout(() => {
          setAlertMessage("");
        }, 5000);
      })
      .finally(() => {
        setLoandig(false);
      });
  };

  const sendPDFViaEmail = () => {
    if (loading) {
      return;
    }

    const params = {
      fullName: fName,
      contactNumber: Number(phone),
      source: "web",
      message: message,
      categoryId: categoryId,
      customerEmailId: email,
    };
    localStorage.setItem("UserDetails", JSON.stringify(params));

    setLoandig(true);
    API_SERVICE.sendPDFViaEmail(params)
      .then(({ data }: any) => {
        setAlertTitle("");
        setAlertMessage(data?.payload ?? "send PDF via email successed");
        setShowMainView(false);
        setTimeout(() => {
          setAlertMessage("");
          callback(true);
        }, 3000);
      })
      .catch((e: any) => {
        setAlertTitle("Failed");
        setAlertMessage(API_SERVICE.handleErrors(e));
        setTimeout(() => {
          setAlertMessage("");
        }, 5000);
      })
      .finally(() => {
        setLoandig(false);
      });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if ((categoryId?.length ?? 0) > 0) {
      sendPDFViaEmail();
    } else {
      shareProductViaEmail();
    }
  };

  const renderMainView = () => {
    return (
      <div id="myModal" className="modal">
        <div className="modal-content pt-10">
          <span
            className="close"
            onClick={() => {
              callback(false);
            }}
          >
            &times;
          </span>
          <p>{`Send ${(categoryId?.length ?? 0) > 0 ? "PDF" : "Enquiry"}`}</p>

          <form
            onSubmit={handleSubmit}
            style={{ display: "grid", gap: "10px" }}
          >
            <AppInput
              dValue={fName}
              label="Name *"
              required
              placeholder="Enter name"
              onChange={(e) => setFName(e.target.value)}
            />
            <AppInput
              dValue={phone}
              label="Phone *"
              required
              type="number"
              placeholder="Enter phone"
              onChange={(e) => setPhone(e.target.value)}
            />
            {(categoryId?.length ?? 0) > 0 && (
              <AppInput
                dValue={email}
                label="Email *"
                required
                type="email"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
              />
            )}
            <AppTextarea
              dValue={message}
              label="Message *"
              required
              placeholder="Enter message"
              onChange={(e) => setMessage(e.target.value)}
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="app-button border"
                type="submit"
                style={{ width: "100px" }}
              >
                {loading ? "Sending..." : "Send"}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading && <AppLoader />}
      {(alertMessage?.length ?? 0) > 0 && (
        <AppAlert
          title={alertTitle}
          message={alertMessage}
          callback={() => {
            setAlertMessage("");
            if (alertTitle === "") {
              callback(true);
            }
            setShowMainView(true);
          }}
        />
      )}
      {showMainView && renderMainView()}
    </>
  );
};

export default SendEnquiry;
