import { useEffect, useState } from "react";
import Enquire from "../enquire/Enquire";
import { API_SERVICE } from "../../shared/service";
import "./ContactUs.scss";

interface IContactUs {}

const ContactUs: React.FunctionComponent<IContactUs> = (props: IContactUs) => {
  const [content, setContent] = useState(
    '<html>\r\n   <head>\r\n      <meta name="viewport" content="width=device-width, initial-scale=0.8">\r\n      <meta name="viewport" content="width=device-width, initial-scale=1">\r\n      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">\r\n   </head>\r\n   <body style="padding: 10">\r\n      <div class="row content">\r\n         <div class="col-lg-6">\r\n            <h2>Manufacturing plant & Head office</h2>\r\n            <p><b>RAMCO EXTRUSION PVT. LTD.</b> <br/>B- 38/2/2, MIDC Area, Opposite BSNL Telephone Exchange, Additional MIDC<br/>Murbad - 421401, Maharashtra, India</p\r\n            <h2>Contact Information:</h2>\r\n            <!-- <p><i style="font-size:20px" class="fa fa-phone"></i> +91(2524)222341 / +91(2524)225971 <br /><i style="font-size:20px" class="fa fa-fax"></i> +91(2524)222341</p> -->\r\n\t\t\t<p><b>Mr. Suresh Jain</b>: +91 9820554076</p>\r\n            <p><b>Mr. Rakesh Jain</b>: +91 9867412128</p>\r\n            <p><i style="font-size:20px" class="fa fa-envelope"></i> ramcoextrusion012@yahoo.in</p>\r\n               <!-- <p><b>Mr. Rakesh Mehta</b>: +91 9167683184 / +91 9588410304</p> --> \r\n         </div>\r\n      </div>\r\n   </body>\r\n</html>'
  );

  useEffect(() => {
    getBusinessCommunication();
  }, []);

  const getBusinessCommunication = () => {
    API_SERVICE.getBusinessCommunication("CONTACT_US")
      .then(({ data }: any) => {
        setContent(data?.payload?.busCommContent ?? content);
      })
      .catch((e: any) => {})
      .finally(() => {});
  };

  return (
    <div className="main-Contact">
      <div className="sub">
        {/* <h2 className="app-title">Contact Us</h2> */}
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      <div style={{}}>
        <Enquire />
      </div>
    </div>
  );
};

export default ContactUs;
