import "./AppInput.scss";

interface IAppInput {
  label?: string;
  placeholder?: string;
  required?: boolean;
  dValue?: string;
  type?: React.HTMLInputTypeAttribute | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

const AppInput: React.FunctionComponent<IAppInput> = (props: IAppInput) => {
  const { label, placeholder, required, dValue, onChange, type } = props;

  return (
    <div className="app-input-main">
      <label>{label}</label>
      <input
        type={type}
        defaultValue={dValue}
        className="app-input"
        required={required}
        placeholder={placeholder}
        onChange={onChange}
        onWheel={(e) => e.currentTarget.blur()}
      />
    </div>
  );
};

export default AppInput;
