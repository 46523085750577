import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import AppHeader from "./screens/layout/Header";
import AppFooter from "./screens/layout/Footer";
import AppRoutes from "./screens/layout/AppRoutes";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <AppHeader />
        <div className="main">
          <AppRoutes />
        </div>
        <AppFooter />
      </BrowserRouter>
    </div>
  );
}

export default App;
